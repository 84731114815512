import React, { useEffect } from "react";
import Header from "../Header/Main";
import Footer from "../Footer/Main";
import LaunchingSoon from "../LaunchingSoon/Main";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Header />
      <LaunchingSoon />
      <div className="page_wrapper">
        <Footer />
      </div>
    </>
  );
}

export default Main;
