import React, { useEffect } from "react";
import Header from "../Header/Main";
import Banner from "../Menhome/Banner";
import HowWork from "../Menhome/HowWork";
import Channels from "../Menhome/Channels";
import Footer from "../Footer/Main";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <Channels />
      <div className="page_wrapper">
        <HowWork />
        <Footer />
      </div>
    </>
  );
}

export default Main;
