import React, { useEffect } from "react";
import Header from "../Header/Main";
import Contactblock from "../Mencontact/Contactblock";
import Contactform from "../Mencontact/Contactform";
import Footer from "../Footer/Main";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Header />
      <div className="page_wrapper">
        <Contactblock />
        <Contactform />
        <Footer />
      </div>
    </>
  );
}

export default Main;
