import React from "react";
import { Link } from "react-router-dom";
import Screenshot1 from "../../assets/images/screenshots/screenshot1.jpg";
import Screenshot2 from "../../assets/images/screenshots/screenshot2.jpg";
import Screenshot3 from "../../assets/images/screenshots/screenshot3.jpg";

function HowWork() {
  return (
    <>
      <section className="how_it_section white_text">
        <div
          className="how_it_inner"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge">Quick &amp; easy</span>
              <h2>How it works in 3 steps</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="screen_frame_img">
                      <img src={Screenshot1} alt="image" />
                    </div>
                    <div className="text">
                      <h3>Subscribe to Channels</h3>
                      <p>
                        Explore and subscribe to the channels that match your
                        interests.
                      </p>
                    </div>
                  </div>
                  <span className="step">01</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="screen_frame_img">
                      <img src={Screenshot2} alt="image" />
                    </div>
                    <div className="text">
                      <h3>Review Messages</h3>
                      <p>
                        Check out the latest messages and insights from your
                        subscribed channels.
                      </p>
                    </div>
                  </div>
                  <span className="step">02</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="screen_frame_img">
                      <img src={Screenshot3} alt="image" />
                    </div>
                    <div className="text">
                      <h3>Like & Save to Watchlist</h3>
                      <p>
                        Like your favorite messages and save them in your
                        watchlist for easy access.
                      </p>
                    </div>
                  </div>
                  <span className="step">03</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWork;
