import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/ft_logo.png";

function Main() {
  return (
    <>
      <footer
        className="white_text"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div className="container">
          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div
              className="logo_side col-md-8"
              style={{ alignItems: "center" }}
            >
              <div className="logo">
                <Link to="#">
                  <img style={{ width: "10rem" }} src={logo} alt="Logo" />
                </Link>
                <div className="news_letter">
                  <h4>Join Our Community</h4>
                </div>
              </div>
            </div>
            <div className="logo_side col-md-4">
              <ul className="social_media">
                <li>
                  <Link to="https://www.instagram.com/drogo.match">
                    <i
                      style={{
                        fontSize: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      className="icofont-instagram"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="https://x.com/drogo_match">
                    <i
                      style={{
                        fontSize: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      className="icofont-twitter"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/in/drogo-match-4345b0324/">
                    <i
                      style={{
                        fontSize: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      className="icofont-linkedin"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/channel/UCGZWXUa4N8m_nUINwo_dqjg">
                    <i
                      style={{
                        fontSize: "18px",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      className="icofont-youtube"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="ft_inner">
              <ul
                style={{ marginLeft: "10px", fontSize: "16px" }}
                className="links"
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
              <div className="copy_text">
                <a
                  style={{ opacity: 0.75, fontSize: "10px" }}
                  href="mailto:support@drogo.live"
                >
                  Email: support@drogo.live
                </a>
              </div>
              <div>
                <p style={{ opacity: 0.5, fontSize: "10px" }}>
                  © Drogo. Copyrights 2024. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
