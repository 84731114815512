import React from "react";
import { Link } from "react-router-dom";
import Mailicon from "../../assets/images/mail_icon.png";
import Phoneicon from "../../assets/images/phone_icon.png";
import Locationicon from "../../assets/images/location_icon.png";

function Contactblock() {
  return (
    <>
      <section className="contact_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="title_badge">Contact us</span>
            <h2>
              Have any questions? <span>Let's talk!</span>
            </h2>
            <p>
              We're here to help with any inquiries you might have about our
              services.
              <br />
              Whether you're looking for more information or need support,
              <br />
              our team is ready to assist you every step of the way.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactblock;
