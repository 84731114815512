import Airtable from "airtable";

// Initialize Airtable base using the environment variable
const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY, // Fetch API key from .env
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID); // Fetch Base ID from .env

const createRecord = async (email) => {
  const date = new Date();
  const formattedDate = formatDate(date);
  try {
    await base("Table 1").create([
      {
        fields: {
          Date: formattedDate,
          Email: email,
        },
      },
    ]);
  } catch (err) {
    console.log(err);
  }
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export default createRecord;
