import React, { useState } from "react";
import createRecord from "../../utils/createRecord";
import { useNavigate } from "react-router-dom";

const SignUpForm = ({ setIsSuccessMessage }) => {
  const [formData, setFormData] = useState({ email: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSuccessMessage(true);
      await createRecord(formData.email);

      setTimeout(() => {
        navigate("/soon");
        setFormData({ email: "" });
      }, 1600);
    } catch (error) {
      setIsSuccessMessage(false);
      console.log("error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        style={{
          background:
            "linear-gradient(135deg, rgba(44, 44, 44, 0.6), rgba(26, 26, 26, 0.6))",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          backdropFilter: "blur(10px)",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderWidth: 0,
          width: "90%",
          borderRadius: "60px",
          color: "white",
        }}
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Enter your email"
        required
      />
      <button type="submit" className="btn sign_up">
        Sign me up
      </button>
    </form>
  );
};

export default SignUpForm;
