import React, { useState } from "react";
import emailjs from "emailjs-com";
import SuccessMessage from "../Success/index";

function Contactform() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    consent: false,
  });

  const [isSuccessMessage, setIsSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // EmailJS service information
    const serviceId = "service_ft4uqvf";
    const templateId = "template_uiqgr2f";
    const userId = "2y-f00lgUHWkC0nmH"; // Replace with your EmailJS user ID
    setIsSuccessMessage(true);

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        setTimeout(() => {
          setIsSuccessMessage(false);
        }, 3000);
      })
      .catch((err) => {
        setIsSuccessMessage(false);
        alert("Failed to send the message.");
      });
  };

  return (
    <>
      {isSuccessMessage && (
        <SuccessMessage text={"We will contact you soon!"} />
      )}
      <section
        className="contact_form white_text row_am"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div className="contact_inner">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-delay={100}
            >
              <span className="title_badge">Message us</span>
              <h2>Send a form</h2>
              <p>Fill up form below, our team will get back soon</p>
            </div>
            <form
              onSubmit={sendEmail}
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name *"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email *"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right">
                  <div className="btn_block">
                    <button className="btn puprple_btn ml-0" type="submit">
                      Submit
                    </button>
                    <div className="btn_bottom" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactform;
