import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import checkmarkAnimation from "../../assets/lottie/checkmark.json"; // Adjust the path based on where you place the file

const SuccessMessage = ({ text }) => (
  <div
    style={{
      backgroundColor: "rgba(20, 20, 20 ,0.8)",
      borderRadius: 20,
      zIndex: 99,
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      textAlign: "center",
      padding: "3rem",
      display: "flex", // Add flexbox
      flexDirection: "column", // Center items vertically
      top: "50%", // Move the container to the center of the screen vertically
      left: "50%", // Move the container to the center horizontally
      transform: "translate(-50%, -50%)", // Shift it back by 50% to perfectly center it
    }}
  >
    <Player
      autoplay
      loop={true}
      src={checkmarkAnimation}
      style={{ height: "12rem", width: "12rem" }}
    />
    <h1 style={{ fontSize: "24px", marginTop: "1rem", color: "white" }}>
      {text}
    </h1>
  </div>
);

export default SuccessMessage;
