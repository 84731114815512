import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logos/icon.png";

const LaunchingSoon = () => {
  const targetDate = new Date("2024-11-01T00:00:00").getTime(); // 1st October 2024
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Function to calculate the remaining time
  function calculateTimeRemaining() {
    const currentTime = new Date().getTime();
    const difference = targetDate - currentTime;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  // Handle the timer ticking
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const { days, hours, minutes, seconds } = timeRemaining;

  return (
    <div
      data-aos="fade-in"
      data-aos-duration={900}
      className="container text-center d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <img style={{ width: "6rem" }} src={logo} alt="Logo" />{" "}
      <h1 className="display-4 mb-4" style={{ fontWeight: "bold" }}>
        Launching Soon
      </h1>
      <p className="lead mb-4" style={{ fontWeight: "400" }}>
        Exciting things are coming! Stay updated with Drogo via email.
      </p>
      <div className="row w-100">
        <div className="col-6 col-md-3 mb-3">
          <div className="card p-3 shadow timer-card">
            <h2 className="display-4">{days}</h2>
            <p>Days</p>
          </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <div className="card p-3 shadow timer-card">
            <h2 className="display-4">{hours}</h2>
            <p>Hours</p>
          </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <div className="card p-3 shadow timer-card">
            <h2 className="display-4">{minutes}</h2>
            <p>Minutes</p>
          </div>
        </div>
        <div className="col-6 col-md-3 mb-3">
          <div className="card p-3 shadow timer-card">
            <h2 className="display-4">{seconds}</h2>
            <p>Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchingSoon;
