import React from "react";
import Zacks from "../../assets/images/channels/zacks.png";
import InvestingCom from "../../assets/images/channels/investing_com.png";
import YahooFinance from "../../assets/images/channels/yahoo_finance.png";
import GuruFocus from "../../assets/images/channels/gurufocus.png";
import CoinGecko from "../../assets/images/channels/coingecko.png";

import TradingView from "../../assets/images/channels/trading_view.png";
import Finviz from "../../assets/images/channels/finviz.png";
import CoinMarketCap from "../../assets/images/channels/coinmarketcap.png";
import CryptoCompare from "../../assets/images/channels/crypto_compare.png";
import MyFxBook from "../../assets/images/channels/myfxbook.png";
import ForexFactory from "../../assets/images/channels/forex_factory.png";
import MarketWatch from "../../assets/images/channels/marketwatch.png";

const clientLogos = [
  YahooFinance,
  InvestingCom,
  Finviz,
  TradingView,
  MarketWatch,
  Zacks,
  GuruFocus,
  CoinGecko,
  CoinMarketCap,
  CryptoCompare,
  MyFxBook,
  ForexFactory,
];

function Channels() {
  return (
    <>
      <section className="row_am our_client">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="title_badge">Featured channels</span>
            <h2>1000+ Channels </h2>
            <p
              style={{
                opacity: 0.5,
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              Join channels of the best worldwide screeners, signals & more
            </p>
          </div>
          <ul className="client_list">
            {clientLogos.map((logo, index) => (
              <li key={index}>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <img style={{ width: "7.5rem" }} src={logo} alt="image" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}

export default Channels;
